<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>顶尖架构师、机器学习大牛、交易大咖、国际竞赛冠军</div>
          <div>跨学科合作，科技赋能投资</div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section
        class="container-lg px-4 d-flex flex-column justify-content-center"
      >
        <div class="text-center lh-lg">
          <div class="fs-3 d-lg-block d-none">
            上海非凸智能科技有限公司（简称“非凸科技”）成立于2018年，是国内领先的智能算法和交易系统服务公司，专注于数智交易领域的研究和开发。
          </div>
          <div class="fs-5 d-lg-none">
            上海非凸智能科技有限公司（简称“非凸科技”）成立于2018年，是国内领先的智能算法和交易系统服务公司，专注于数智交易领域的研究和开发。
          </div>
        </div>
      </section>
      <!--section class="bg-light">
        <div class="py-5">
          <div class="container-lg px-4">
             <div class="row mt-2 mb-5">
            </div>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-5 gy-4">
            <div class="plan col">
              <img
                src="../../assets/img/about/01-复旦.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">硕士职业导师</div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/about/02-高新.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">国家高新技术企业</div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/about/03-csdn.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">人才合作伙伴</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section-->
       <section class="h-100 bg-light">
        <div>
          <div class="row">
            <div class="col-md-6 d-flex flex-column">
              <img
                class="img-fluid d-lg-none"
                src="../../assets/img/about/about_ft.jpg"
                style="height: 300px !important"
              />
              <img
                class="img-fluid d-lg-block d-none"
                src="../../assets/img/about/about_ft.jpg"
              />
            </div>
            <div class="col-md-4 col-10 offset-1 text-center mt-5 pt-5">
              <div class="pb-3 fs-5 text-center lh-lg">
                <p>
                  目前，已开发出一系列产品：非凸智能算法、 非凸底仓增强算法、非凸智能交易系统、非凸绩效分析系统、非凸数据回测系统、非凸模拟仿真系统、非凸运维监控系统、非凸智能路由系统等，服务于业内知名客户国泰君安、中金财富、银河证券、国投证券、海通证券、国信证券、财通证券、中泰证券、东北证券、国联证券、方正证券、广发证券、太平洋证券等。凭借全球领先的技术、突出的数据研究能力，以及严谨的算法策略，为投资者创造出突出的业绩。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <!--section class="bg-light">
        <div class="container py-5 mb-5">
          <div class="row text-center gy-5" style="min-height: 20rem">
           <div class="col-md-3 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">100+</div>
              <div>团队规模超百人</div>
            </div>
            <div class="col-md-3 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">10+</div>
              <div>核心成员10年以上从业经验</div>
            </div>
            <div class="col-md-3 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">90%+</div>
              <div>研发团队毕业于国内外知名院校</div>
            </div>
            <div class="col-md-3 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">20+</div>
              <div>发明专利及软件著作权</div>
            </div>
          </div>
        </div>
      </section-->
      <section class="">
        <div class="timeline timeline-one p-5">
          <div class="timeline-item">
            <h2 class="h5 my-3">2018年</h2>
            <p>公司成立</p>
          </div>
          <div class="timeline-item">
            <h2 class="h5 my-3">2019年</h2>
            <p>非凸智能算法V1.0发布<br />天使轮融资</p>
          </div>
          <div class="timeline-item">
            <h2 class="h5 my-3">2020年</h2>
            <p>非凸智能算法V2.0发布</p>
          </div>
          <div class="timeline-item">
            <h2 class="h5 my-3">2021年</h2>
            <p>非凸智能交易系统上线<br />合作客户日均交易超1亿<br />北京、成都分公司成立</p>
          </div>
          <div class="timeline-item">
            <h2 class="h5 my-3">2022年</h2>
            <p>非凸智能算法上线首家券商<br />非凸底仓增强算法正式发布上线<br />算法交易日均成交额50亿</p>
          </div>
          <div class="timeline-item">
            <h2 class="h5 my-3">2023年</h2>
            <p>非凸底仓增强算法上线首家券商<br />非凸底仓增强 APP方案上线首家券商<br />非凸绩效评价系统、策略研发平台上线</p>
          </div>
          <div class="timeline-item">
            <h2 class="h5 my-3">至今</h2>
            <p>非凸金融客户端V3.0正式上线<br />算法交易日均成交额100亿<br />深圳分公司成立</p>
          </div>
        </div>
      </section>

      <!--section>
      <div class="bg-light">
        <div class="container-lg px-4 mb-0">
          <div
            class="advantage-title img-title d-inline-block border-top"
          ></div>
          <div class="row p-2 gy-5">
            <div class="col-md-4  p-3 text-center">
              <div id="professional" class="circle mb-5"></div>
              <h5 class="fs-1">专业</h5>
              <p class="fs-5">
                团队来自海内外知名院校及科技/金融/互联网公司，具备强大的编程技能和敏锐的投资思维
              </p>
            </div>
            <div class="col-md-4  p-3 text-center">
              <div id="innovation" class="circle mb-5"></div>
              <h5 class="fs-1">创新</h5>
              <p class="fs-5">
                全面升级到互联网新一代技术架构，结合机器学习和深度学习等新兴技术，逐步完成各类智能交易算法的研发迭代
              </p>
            </div>
            <div class="col-md-4  p-3 text-center">
              <div id="reliability" class="circle mb-5"></div>
              <h5 class="fs-1">可靠</h5>
              <p class="fs-5">
                超低成本，极速交易，持续为券商、私募等众多大型金融机构提供优质的算法交易解决方案
              </p>
              </div>
            </div>
          </div>
        </div>
      </section-->
      <section class="h-100 bg-light">
        <div>
          <div class="row">
            <div class="col-md-6 d-flex flex-column">
              <img
                class="img-fluid"
                src="../../assets/img/about/about_research.jpg"
              />
            </div>
            <div class="col-md-4 col-10 offset-1 text-center mt-5 pt-5">
              <div class="pb-3 fs-5 text-center lh-lg">
                <p>
                  我们通过提升计算机算力，使用机器学习和深度学习等新兴技术，推动算法交易的落地与应用。我们在算法策略研究方面积累了丰富的实战经验，历经了不同的市场风格。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="">
        <div class="container-lg px-4 mb-4">
          <div
            class="advantage-title img-title d-inline-block border-top"
          ></div>
          <div class="row p-2 gy-5">
            <div class="col-md-6 p-3 text-center">
              <div id="professional" class="circle mb-5"></div>
              <h5 class="fs-1">专业团队</h5>
              <p class="fs-5">
                工程开发和策略研究人员超80%，毕业于海内外知名院校，多人获得ACM/ICPC、NOI、CMO等竞赛奖项
              </p>
            </div>
            <div class="col-md-6 p-3 text-center">
              <div id="innovation" class="circle mb-5"></div>
              <h5 class="fs-1">技术研发</h5>
              <p class="fs-5">
                百人专业团队，十几年开发经验，雄厚技术实力；对技术变革方向，始终保持着敏锐的观察力和行动力
              </p>
            </div>
            <div class="col-md-6 p-3 text-center">
              <div id="platform" class="circle mb-5"></div>
              <h5 class="fs-1">平台优势</h5>
              <p class="fs-5">
                软硬件基础设施，具备强大的计算性能、存储性能、互联通讯能力等；自建数据中心，打造更强的算法模型
              </p>
              </div>
               <div class="col-md-6 p-3 text-center">
              <div id="reliability" class="circle mb-5"></div>
              <h5 class="fs-1">数据挖掘</h5>
              <p class="fs-5">
                持续更新市场行情数据、金融市场其他相关数据、宏观经济等数据，科学地对待数据的挖掘和分析
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Company",
  data() {
    return {
      selectLocation: null,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.img-title {
  font-family: monospace;
  font-size: 40px;
  color: $--ft-primary-3;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: capitalize;
}
.plan img {
  width: 8rem;
}
.plan {
  text-align: center;
}
.advantage-title {
  margin-top: 3rem;
  border-width: 5px !important;
}
img.img-fluid {
  height: 400px;
}
.img-thumbnail {
  border: none;
}
.timeline {
  width: 100%;
}

.timeline.timeline-one .timeline-item:nth-child(2n + 1) {
  margin-left: 0;
  padding-right: 1.5rem;
  padding-left: 0;
  text-align: right;
}

.timeline.timeline-one .timeline-item {
  position: relative;
  width: 50%;
  margin-left: 50%;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  text-align: left;
}

.timeline.timeline-one .timeline-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: 0.425rem;
  margin-left: -0.5rem;
  border: 0.0625rem solid $--ft-light-gray;
  border-radius: 50%;
  background-color: $--ft-primary-3;
}

.timeline.timeline-one .timeline-item:nth-child(2n + 1):before {
  margin-right: -0.5rem;
}

.timeline.timeline-one .timeline-item:nth-child(2n + 1):after {
  margin-right: -0.0625rem;
}

.timeline.timeline-one .timeline-item:nth-child(2n + 1):before,
.timeline.timeline-one .timeline-item:nth-child(2n + 1):after {
  right: 0;
  left: auto;
}

.timeline.timeline-one .timeline-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: -0.1875rem;
  width: 0.0625rem;
  margin-top: 1.875rem;
  margin-left: -0.0625rem;
  background-color: $--ft-light-gray;
}
#multielement.circle {
  background-image: url("../../assets/img/about/multielement.jpg");
}
#professional.circle {
  background-image: url("../../assets/img/about/professional.jpg");
}
#innovation.circle {
  background-image: url("../../assets/img/about/innovation.jpg");
}
#reliability.circle {
  background-image: url("../../assets/img/about/reliability.jpg");
}
#detail.circle {
  background-image: url("../../assets/img/about/detail.jpg");
}
#freedom.circle {
  background-image: url("../../assets/img/about/freedom.jpg");
}
#platform.circle {
  background-image: url("../../assets/img/about/platform.jpg");
}
.circle {
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  align-self: center;
  display: inline-block;
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/about/company_banner.jpg");
  background-position-y: 40%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.btn-light {
  border: 1px solid black;
}
.btn-light:hover {
  border: 1px solid black;
  box-shadow: 0px 2px 5px 0px black;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.25rem;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .cover-text > .btn {
    margin: auto;
    display: block;
    width: 7rem;
  }
}
</style>
